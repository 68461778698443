import radioactivity from "@assets/radioactivity.webp"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ScrollLayout from "../../components/ScollLayout"

export default function InterestTopic4Page() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [position, setPosition] = useState("center")
  const [opacity, setOpacity] = useState(1)

  const moveLeft = () => {
    setPosition("left")
    setOpacity(0)
  }

  const moveRight = () => {
    setPosition("right")
    setOpacity(0)
  }

  const handleClick = () => {
    setTimeout(() => {
      navigate("/onboarding/goal")
    }, 1000)
  }

  return (
    <ScrollLayout>
      <div className="flex h-full flex-col gap-16">
        <div className="shrink-0">
          <h1 className="heading">
            {t("pages.onboarding.interestTopics.heading")}
          </h1>
          <h1 className="subheading">
            4{t("pages.onboarding.interestTopics.current")}
          </h1>
        </div>
        <div className="flex flex-col items-center justify-center gap-8">
          <div
            className={`transition-all duration-500 ease-in-out ${
              position === "left"
                ? "translate-x-[-100%]"
                : position === "right"
                  ? "translate-x-[100%]"
                  : ""
            }`}
            style={{ opacity }}
          >
            <img
              src={radioactivity}
              alt="radioactivity"
              className="size-40 w-full object-scale-down"
            />
            <h1 className="heading pt-8 font-sentient">
              {t("pages.onboarding.interestTopics.topic4")}
            </h1>
          </div>
        </div>

        <div className="flex flex-row items-center justify-center gap-8 px-16 py-8">
          <button
            onClick={() => {
              moveLeft()
              handleClick()
            }}
            className="rounded-2xl border border-custom-gray px-8 py-6 text-left text-center font-sentient text-lg font-semibold"
          >
            <span className="mr-1 pr-2">👎</span>
            {t("pages.onboarding.interestTopics.no")}
          </button>

          <button
            onClick={() => {
              moveRight()
              handleClick()
            }}
            className="rounded-2xl border border-custom-gray px-8 py-6 text-left text-center font-sentient text-lg font-semibold"
          >
            <span className="mr-1 pr-2">👍</span>
            {t("pages.onboarding.interestTopics.yes")}
          </button>
        </div>
      </div>
    </ScrollLayout>
  )
}
