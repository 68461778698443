import appstore from "@assets/appstore.webp"
import logo from "@assets/logo.svg"
import playstore from "@assets/playstore.webp"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLoaderData } from "react-router-dom"
import { GetSubscriptionLoaderData } from "src/loaders/getSubscriptionLoader"
import ScrollLayout from "../../components/ScollLayout"
import SubscriptionDialog from "../../components/SubscriptionDialog"

export default function SubscriptionPage() {
  const { revenueCatSubscription, userEmail } =
    useLoaderData() as GetSubscriptionLoaderData

  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false)

  const { t } = useTranslation()

  const getAppUrlForDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    if (userAgent.includes("android")) {
      return import.meta.env.VITE_APP_URL_GOOGLE_PLAY_STORE as string
    } else {
      return import.meta.env.VITE_APP_URL_APPLE_APP_STORE as string
    }
  }

  const handleClick = () => {
    const appUrl = getAppUrlForDevice()

    window.open(appUrl, "_blank")
  }

  return (
    <>
      <ScrollLayout>
        <div className="custom-container flex shrink-0 items-center justify-between gap-8">
          <img alt="logo" className="h-14 w-14" src={logo} />

          {revenueCatSubscription && (
            <button
              className="rounded-full bg-black p-4 font-sentient text-sm font-bold text-white disabled:opacity-10"
              onClick={() => setOpenSubscriptionDialog(true)}
              type="button"
            >
              {t("pages.account.subscription.subscriptionInfo")}
            </button>
          )}
        </div>

        <div className="my-8 flex grow flex-col justify-center space-y-7">
          <h1 className="heading pb-8">
            {t("pages.account.subscription.heading")}
          </h1>

          <ul className="space-y-12 text-center">
            <li className="font-sentient text-xl font-bold underline">
              {t("pages.account.subscription.noNewSubscription")}
            </li>

            <li className="font-sentient text-base">
              {t("pages.account.subscription.signIntoApp")}
              <h1 className="font-sentient text-base font-bold">
                {userEmail ?? "support@geniusacademy.app"}
              </h1>
            </li>
            <button
              onClick={handleClick}
              className="custom-container flex shrink-0 flex-col items-center justify-center gap-8"
            >
              <img src={appstore} alt="Apple logo" className="w-1/2 sm:w-1/3" />
              <img
                src={playstore}
                alt="Apple logo"
                className="w-1/2 sm:w-1/3"
              />
            </button>
          </ul>
        </div>
      </ScrollLayout>

      {openSubscriptionDialog && revenueCatSubscription && (
        <SubscriptionDialog
          onClose={() => setOpenSubscriptionDialog(false)}
          open={openSubscriptionDialog}
          revenueCatSubscription={revenueCatSubscription}
        />
      )}
    </>
  )
}
