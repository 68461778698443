import behavior from "@assets/behavior.webp"
import cubism from "@assets/cubism.webp"
import modernism from "@assets/modernism.webp"
import relativity from "@assets/relativity.webp"
import stoicism from "@assets/stoicism.webp"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { useState } from "react"
import useLocalStorage from "react-use/lib/useLocalStorage"
import ScrollLayout from "../../components/ScollLayout"

export default function InterestTopic2Page() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [position, setPosition] = useState("center")
  const [opacity, setOpacity] = useState(1)
  const [value] = useLocalStorage<string | null>("selectedTopic", null)

  const moveLeft = () => {
    setPosition("left")
    setOpacity(0)
  }

  const moveRight = () => {
    setPosition("right")
    setOpacity(0)
  }

  const handleClick = () => {
    setTimeout(() => {
      navigate("/onboarding2/topic3")
    }, 1000)
  }

  function getImage() {
    switch (value) {
      case "science":
        return relativity
      case "literature":
        return modernism
      case "economics":
        return behavior
      case "philosophy":
        return stoicism
      case "art":
        return cubism
      default:
        return relativity
    }
  }

  return (
    <ScrollLayout>
      <div className="flex h-full flex-col gap-16">
        <div className="shrink-0">
          <h1 className="heading">
            {t("pages.onboarding.interestTopics.heading")}
          </h1>
          <h1 className="subheading">
            2{t("pages.onboarding.interestTopics.current")}
          </h1>
        </div>
        <div className="flex flex-col items-center justify-center gap-8">
          <div
            className={`transition-all duration-500 ease-in-out ${
              position === "left"
                ? "translate-x-[-100%]"
                : position === "right"
                  ? "translate-x-[100%]"
                  : ""
            }`}
            style={{ opacity }}
          >
            <img
              src={getImage()}
              alt="romanticism"
              className="size-40 w-full object-scale-down"
            />
            <h1 className="heading pt-8 font-sentient">
              {t(`pages.onboarding.topicInterest.${value}Topic2`)}
            </h1>
          </div>
        </div>

        <div className="flex flex-row items-center justify-center gap-8 px-16 py-8">
          <button
            onClick={() => {
              moveLeft()
              handleClick()
            }}
            className="rounded-2xl border border-custom-gray px-8 py-6 text-left text-center font-sentient text-lg font-semibold"
          >
            <span className="mr-1 pr-2">👎</span>
            {t("pages.onboarding.interestTopics.no")}
          </button>

          <button
            onClick={() => {
              moveRight()
              handleClick()
            }}
            className="rounded-2xl border border-custom-gray px-8 py-6 text-left text-center font-sentient text-lg font-semibold"
          >
            <span className="mr-1 pr-2">👍</span>
            {t("pages.onboarding.interestTopics.yes")}
          </button>
        </div>
      </div>
    </ScrollLayout>
  )
}
