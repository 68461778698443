import Ad from "@components/Ad";
import Features from "@components/Features";
import FrequentlyAskedQuestions from "@components/FrequentlyAskedQuestions";
import LimitedTimeOfferDialog from "@components/LimitedTimeOfferDialog";
import Plans from "@components/Plans";
import ScrollLayout from "@components/ScollLayout";
import SubscriptionForm from "@components/SubscriptionForm";
import ErrorPage from "@pages/ErrorPage";
import LoadingPage from "@pages/LoadingPage";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Await, useLoaderData } from "react-router-dom";
import {
  GetPricesData,
  GetPricesLoaderResult,
} from "src/loaders/getPricesLoader";
import { Plan } from "src/types";


export default function PlansPage() {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan>("yearly");

  const data = useLoaderData() as GetPricesLoaderResult;
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsDialogOpen(true);
    }, 30000);

    return () => clearTimeout(timeout);
  }, []);


  if (isSubmitting) return <LoadingPage />;

  return (
    <Suspense fallback={<LoadingPage />}>
      <Await errorElement={<ErrorPage />} resolve={data.getPricesData}>
        {(getPricesData: GetPricesData) => (
          <>
            <ScrollLayout>
              <h1 className="heading mb-14 font-oswald">
                {t("pages.checkout.plans.heading")}
              </h1>

              <section className="mb-16 max-w-4xl mx-auto px-5">
                <Plans
                  currency={getPricesData.currency}
                  introPriceCoupon={
                    getPricesData.subscriptionStatus === "none"
                      ? getPricesData.introPriceCoupon
                      : undefined
                  }
                  isSubmitting={isSubmitting}
                  setIsSubmitting={setIsSubmitting}
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                  weeklyPrice={getPricesData.weeklyPrice}
                  yearlyPrice={getPricesData.yearlyPrice}
                />
              </section>

              <section className="mb-12">
                <Ad />
              </section>

              <section className="mb-12 custom-container">
                <Features />
              </section>

              <section className="mb-24 custom-container">
                <FrequentlyAskedQuestions />
              </section>
            </ScrollLayout>

            <div className="w-full sticky bottom-0 py-4 shrink-0 bg-white justify-center items-center">
              <div className="custom-container">
                <SubscriptionForm
                  buttonTitle={t("shared.continue")}
                  couponId={
                    getPricesData.subscriptionStatus === "none"
                      ? getPricesData.introPriceCoupon?.id
                      : undefined
                  }
                  currency={getPricesData.currency}
                  isSubmitting={isSubmitting}
                  plan="weekly"
                  priceId={
                    selectedPlan === "weekly"
                      ? getPricesData.weeklyPrice?.id
                      : getPricesData.yearlyPrice?.id
                  }
                                    setIsSubmitting={setIsSubmitting}
                />

                <p className="font-bold font-sentient text-center pt-2">
                  {t("pages.checkout.plans.cancelAnytime")}
                </p>
              </div>
            </div>
            <LimitedTimeOfferDialog
              currency={getPricesData.currency}
              isSubmitting={isSubmitting}
              onClose={() => setIsDialogOpen(false)}
              open={isDialogOpen}
              periodAmount={12}
              periodUnit="month"
              setIsSubmitting={setIsSubmitting}
              stripeIntroPriceCoupon={
                getPricesData.subscriptionStatus === "none"
                  ? getPricesData.introPriceCoupon
                  : undefined
              }
              stripePrice={getPricesData.yearlyPrice}
              plan='yearly'
            />

          </>
        )}
      </Await>
    </Suspense>
  )
}
