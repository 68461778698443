import logo from "@assets/logo.svg"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Button from "../../components/Button"
import { Confetti } from "../../components/Confetti"
import ScrollLayout from "../../components/ScollLayout"
import Typewriter from "../../components/Typewriter"

export default function WelcomePage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [checked, setChecked] = useState<boolean>(true)

  const handleClick = () => {
    navigate("/onboarding2/dive")
  }

  return (
    <ScrollLayout>
      {checked && (
        <Confetti
          onFinish={() => {
            return
          }}
        />
      )}
      <div className="custom-container flex h-full flex-col text-center">
        <div className="flex grow flex-col items-center justify-center gap-0">
          <img
            src={logo}
            alt="Albert Einstein"
            className="size-40 object-scale-down	"
          />

          <Typewriter
            text={t("pages.onboarding.welcome.title")}
            onFinish={() => setChecked(false)}
          />
        </div>

        <div className="custom-container max-w-sm shrink-0 py-8">
          <Button onClick={handleClick} title={t("shared.continue")} />
        </div>
      </div>
    </ScrollLayout>
  )
}
