import lightbulb from "@assets/lightbulb.webp"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Button from "../../components/Button"
import ScrollLayout from "../../components/ScollLayout"
import Typewriter from "../../components/Typewriter"

export default function BigIdeasPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = () => {
    navigate("/onboarding/focus")
  }

  return (
    <ScrollLayout>
      <div className="custom-container flex h-full flex-col text-center">
        <div className="flex grow flex-col items-center justify-center gap-8 px-8">
          <img
            src={lightbulb}
            alt="Lightbulb"
            className="size-40 object-scale-down	"
          />

          <Typewriter text={t("pages.onboarding.bigIdeas.title")} />
        </div>

        <div className="custom-container max-w-sm shrink-0 py-8">
          <Button onClick={handleClick} title={t("shared.continue")} />
        </div>
      </div>
    </ScrollLayout>
  )
}
