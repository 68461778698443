import { useTranslation } from "react-i18next";
import { Plan, StripeCoupon, StripePrice } from "src/types";
import localizeCurrency from "../helpers/localizeCurrency";
import Dialog from "./Dialog";
import SubscriptionForm from "./SubscriptionForm";

interface Props {
  currency: string;
  isSubmitting: boolean;
  open: boolean;
  onClose: () => void;
  periodAmount: number;
  periodUnit: string;
  setIsSubmitting: (isSubmitting: boolean) => void;
  stripeIntroPriceCoupon: StripeCoupon | undefined;
  stripePrice: StripePrice | undefined;
  plan: Plan;
}

export default function LimitedTimeOfferDialog({
  currency,
  isSubmitting,
  onClose,
  open,
  periodAmount,
  periodUnit,
  setIsSubmitting,
  stripeIntroPriceCoupon,
  stripePrice,
  plan,
}: Props) {
  const { t } = useTranslation();

  if (!stripeIntroPriceCoupon || !stripePrice) return null;

  const hasCurrencyOption =
     !!stripePrice.currency_options?.[currency]?.unit_amount_decimal;

   const price = parseFloat(
     hasCurrencyOption
       ? stripePrice.currency_options[currency].unit_amount_decimal
       : stripePrice?.unit_amount_decimal,
   );

   const priceCurrency = hasCurrencyOption ? currency : stripePrice.currency;

  if (!price) return null;

  const percentOff = stripeIntroPriceCoupon?.percent_off;
  const introPrice = percentOff && price * (1 - percentOff / 100);

  const localizePrice = (price: number) =>
    localizeCurrency({ amountInCents: price, currency: priceCurrency });

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="text-center text-black">
        <p className="mb-2 text-2xl font-bold font-oswald">
          {t("pages.checkout.plans.dialog.limitedTimeOffer")}
        </p>

        <p className="text-4xl font-bold font-oswald">{periodAmount}</p>

        <p className="text-lg mb-3 uppercase font-oswald">
          {t(`pages.checkout.plans.${periodUnit}`, { count: periodAmount })}
        </p>

        {percentOff && (
          <p className="mb-2 line-through font-sentient">{localizePrice(price)}</p>
        )}

        <p className="mb-4 font-semibold  font-sentient">
          {t("pages.checkout.plans.firstYearSpecialOffer", {
            price: localizePrice(percentOff ? introPrice : price),
          })}
        </p>

        <SubscriptionForm
          buttonTitle={t("pages.checkout.plans.dialog.startNow")}
          couponId={stripeIntroPriceCoupon?.id}
          currency={priceCurrency}
          isSubmitting={isSubmitting}
          priceId={stripePrice?.id}
          setIsSubmitting={setIsSubmitting}
          plan={plan}
        />

        <p className="mt-4 font-semibold  font-sentient">
          {t("pages.checkout.plans.moneyBackGuarantee")}
        </p>
      </div>
    </Dialog>
  );
}
