import Button from "@components/Button";
import { trackSubscriptionEvent } from "@helpers/facebookTracking";
import localizeCurrency from "@helpers/localizeCurrency";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentIntent } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface Props {
  amount: number;
  clientSecret: string;
  currency: string;
  plan: "weekly" | "yearly";
  subscriptionId: string;
  userEmail: string | undefined;
}

export default function CheckoutForm({
  amount,
  clientSecret,
  currency,
  plan,
  subscriptionId,
  userEmail,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>(
    undefined,
  );

  const elements = useElements();
  const location = useLocation();
  const stripe = useStripe();
  const { t } = useTranslation();

  const getPrice = () =>
    localizeCurrency({
      amountInCents: paymentIntent?.amount,
      currency: paymentIntent?.currency,
    });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!elements || !stripe) return;

    setIsSubmitting(true);

    const origin = window.location.origin;
    const pathname = "/checkout/payment-completion";
    const urlSearchParams = new URLSearchParams(location.search);

    urlSearchParams.append("subscription_id", subscriptionId);

    const returnUrl = `${origin}${pathname}?${urlSearchParams.toString()}`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: returnUrl },
    });

    setIsSubmitting(false);

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      throw error;
    }
  };

  useEffect(() => {
    if (!clientSecret || !stripe) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        setPaymentIntent(paymentIntent);

        if (paymentIntent?.last_payment_error) {
          if (
            paymentIntent.last_payment_error.code ===
            "payment_intent_authentication_failure"
          ) {
            setMessage(t("components.checkoutForm.paymentFailed"));
          } else {
            setMessage(t("components.checkoutForm.somethingWentWrong"));
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  }, [clientSecret, setPaymentIntent, stripe, t]);

  useEffect(() => {
    if (!paymentIntent) return;

    const trackEvent = async () => {
      await trackSubscriptionEvent({
        plan: plan,
        currency: paymentIntent.currency,
        isPurchase: false,
        paymentMethodType: "card",
      });
    };

    trackEvent().catch((error) => console.error(error));
  }, [paymentIntent, plan]);

  useEffect(() => {
    const resetSubmittingState = () => {
      setIsSubmitting(false);
    };

    window.addEventListener("pageshow", resetSubmittingState);
    document.addEventListener("visibilitychange", resetSubmittingState);

    return () => {
      window.removeEventListener("pageshow", resetSubmittingState);
      document.removeEventListener("visibilitychange", resetSubmittingState);
    };
  }, []);

  return (
    <form
      className="flex h-full flex-col justify-between space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="rounded-3xl bg-white p-5">
        <PaymentElement
          options={{
            defaultValues: {
              billingDetails: {
                email: userEmail,
              },
            },
            paymentMethodOrder: ["card", "link", "paypal", "apple_pay", "google_pay"],
          }}
        />
      </div>

      {message && (
        <p className="text-center text-lg font-bold text-custom-error">
          {message}
        </p>
      )}

      <div>
        {paymentIntent && (
          <>
            {paymentIntent.amount !== amount ? (
              <>
                <p className="font-bold line-through">
                  {t(
                    `pages.checkout.payment.${plan === "weekly" ? "thisWeek" : "thisYear"}`,
                    {
                      price: localizeCurrency({
                        amountInCents: amount,
                        currency,
                      }),
                    },
                  )}
                </p>

                <p className="font-bold">
                  {t(
                    plan === "weekly"
                      ? "pages.checkout.payment.firstWeekSpecialOffer"
                      : "pages.checkout.payment.firstYearSpecialOffer",
                    { price: getPrice() },
                  )}
                </p>
              </>
            ) : (
              <p className="font-bold">
                {t(
                  `pages.checkout.payment.${plan === "weekly" ? "thisWeek" : "thisYear"}`,
                  { price: getPrice() },
                )}
              </p>
            )}
          </>
        )}

        <Button
          className="mt-7"
          disabled={!elements || !stripe}
          submitting={isSubmitting}
          title={t("pages.checkout.payment.submitPayment")}
          type="submit"
        />
      </div>
    </form>
  );
}
