import apple from "@assets/appleTrust.webp"
import google from "@assets/googleTrust.webp"
import { useTranslation } from "react-i18next"
import MovingGeniuses from "./MovingGeniuses"

export default function Ad() {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-4">
        <p className="heading mb-8">{t("components.ad.heading")}</p>

        <div className="flex flex-row justify-center gap-8 pb-4">
          <img
            src={apple}
            alt="Apple logo"
            className="w-1/4 object-scale-down sm:w-1/6"
          />
          <img
            src={google}
            alt="Apple logo"
            className="w-1/4 object-scale-down sm:w-1/6"
          />
        </div>

        <p className="font-sentient text-2xl">{t("components.ad.has")}</p>

        <p className="font-sentient text-2xl font-bold">
          {t("components.ad.subscribers")}
        </p>
        <p className="text-2xl">⭐⭐⭐⭐⭐</p>
      </div>

      <MovingGeniuses />
    </>
  )
}
