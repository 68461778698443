import { hashValue } from "@helpers/hashValue"
import { FACEBOOK_CLICK_ID_KEY } from "@helpers/localStorage"
import {
  getUserCity,
  getUserCountryCode,
  getUserEmail,
  getUserId,
  getUserZipCode,
} from "@helpers/sessionStorage"
import { getForeignExchangeRate, trackEvent } from "@helpers/supabase"
import * as Sentry from "@sentry/react"
import Cookies from "js-cookie"
import { EventName, Plan, StripePaymentMethodType } from "src/types"
import { v4 as uuidv4 } from "uuid"
import { getNetAmount } from "./backend"

export async function trackCompleteRegistrationEvent() {
  const eventId = createEventId()
  const eventName = "CompleteRegistration"

  await trackEventFromServer({ eventId, eventName })
  await trackEventFromClient({ eventId, eventName })
}

export async function trackSubscriptionEvent({
  isPurchase,
  currency,
  paymentMethodType,
  plan,
}: {
  isPurchase: boolean;
  currency: string;
  paymentMethodType: StripePaymentMethodType;
  plan: Plan;
}) {
  const sanitizedCurrency = sanitizeCurrency(currency)
  const foreignExchangeRate = await getForeignExchangeRate(currency)
  const grossCLVInEuros = plan === "yearly" ? 90.54 : 77.80
  const countryCode = await getUserCountryCode()

  const clvInCurrency = foreignExchangeRate ? grossCLVInEuros * foreignExchangeRate : grossCLVInEuros

  const netAmount = await getNetAmount({
    countryCode,
    currency: sanitizedCurrency,
    grossAmount: clvInCurrency,
    paymentMethodType,
  })

  const eventId = createEventId()
  const eventName = isPurchase ? "Purchase" : "InitiateCheckout"

  await trackEventFromServer({
    currency: sanitizedCurrency,
    eventId,
    eventName,
    value: netAmount,
  })

  await trackEventFromClient({
    currency: sanitizedCurrency,
    eventId,
    eventName,
    value: netAmount,
  })
}

function createEventId(): string {
  return uuidv4()
}

function sanitizeCurrency(currency: string): string {
  return currency.toUpperCase()
}

async function trackEventFromClient({
  currency,
  eventId,
  eventName,
  value,
}: {
  currency?: string;
  eventId: string;
  eventName: EventName;
  value?: number;
}) {
  const userCity = await getUserCity()
  const userCountryCode = (await getUserCountryCode()).toLowerCase()
  const userEmail = await getUserEmail()
  const userId = await getUserId()
  const userZipCode = await getUserZipCode()

  const payload =
    eventName === "CompleteRegistration" ? {} : { currency, value }

  return new Promise<void>((resolve, reject) => {
    if (fbq) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      fbq("init", import.meta.env.VITE_FACEBOOK_PIXEL_ID, {
        country: userCountryCode,
        ct: userCity,
        em: userEmail,
        external_id: userId,
        zp: userZipCode,
      })

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      fbq("track", eventName, payload, {
        eventID: eventId,
      })

      resolve()
    } else {
      Sentry.captureException("Facebook Pixel not loaded")
      reject(new Error("Facebook Pixel not loaded"))
    }
  })
}

async function trackEventFromServer({
  currency,
  eventId,
  eventName,
  value,
}: {
  currency?: string;
  eventId: string;
  eventName: EventName;
  value?: number;
}) {
  const eventSourceUrl = window.location.href
  const fbcFromCookie = Cookies.get("_fbc")
  const fbcFromLocalStorage = localStorage.getItem(FACEBOOK_CLICK_ID_KEY)
  const fbc = fbcFromCookie ?? fbcFromLocalStorage
  const fbp = Cookies.get("_fbp")
  const userCity = await getUserCity()
  const userCountryCode = (await getUserCountryCode()).toLowerCase()
  const userEmail = await getUserEmail()
  const userId = await getUserId()
  const userZipCode = await getUserZipCode()

  await trackEvent({
    currency,
    eventId,
    eventName,
    eventSourceUrl,
    fbc,
    fbcFromCookie,
    fbcFromLocalStorage,
    fbp,
    pixelId: import.meta.env.VITE_FACEBOOK_PIXEL_ID as string,
    userCity: await hashValue(userCity),
    userCountryCode: await hashValue(userCountryCode),
    userEmail: await hashValue(userEmail),
    userId: await hashValue(userId),
    userZipCode: await hashValue(userZipCode),
    value,
  })
}